// Axios Middleware
import Request from '../../../Middleware/Requests/RequestMaster';


// Get All Categories
export const GetAllCategoriesAction = async () => {
    const response = await Request.get( 'Market/Category' );
    return response.data;
}

const headers = {
    'Authorization': 'Bearer ' + localStorage.getItem('token'),
    'Content-Type': 'application/json'
};

// Get DropDown Categories
export const GetDropDownCategoriesAction = async () => {
    const response = await Request.get( 'Market/Category/DropDownList', {headers});
    return response.data;
}